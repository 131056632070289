import { type InternationalizationText, useLocales } from '@sevenrooms/core/locales'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { MarketingPreferenceTableHeaderInfoBubble } from './MarketingPreferenceTableHeaderInfoBubble'

interface MarketingPreferenceTableHeaderCellProps {
  headerLabel: InternationalizationText
  infoBubbleLines?: InternationalizationText[]
}
export function MarketingPreferenceTableHeaderCell({ headerLabel, infoBubbleLines }: MarketingPreferenceTableHeaderCellProps) {
  const { formatMessage } = useLocales()
  if (infoBubbleLines) {
    return (
      <HStack columnGap="xs" justifyContent="center">
        {formatMessage(headerLabel)}
        <MarketingPreferenceTableHeaderInfoBubble lines={infoBubbleLines} />
      </HStack>
    )
  }
  return <>{formatMessage(headerLabel)}</>
}
