import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useLazyGetClientMarketingPreferencesForVenueQuery,
  useLazyGetClientMarketingPreferencesQuery,
  useUpdateClientMarketingPreferencesMutation,
  type ClientVenueMarketingPreference,
  ClientMarketingPreferenceOptInStatus,
} from '@sevenrooms/core/api'
import { useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, Form, IconButton, SubmitButton } from '@sevenrooms/core/ui-kit/form'
import {
  Loader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  TableBody,
  Table,
  VStack,
  TableHeader,
  notify,
  HStack,
  Box,
  Tooltip,
} from '@sevenrooms/core/ui-kit/layout'
import { Text, StatusLabel } from '@sevenrooms/core/ui-kit/typography'
import { mgrClientMarketingPreferencesMessages } from '../../locales'
import { useMarketingPreferencesFormSchema, type MarketingPreferencesForm } from './Form/MarketingPreferences.zod'
import { VenuePreferenceRow } from './Form/VenuePreferenceRow'
import { MarketingPreferenceTableHeaderCell } from './Table/MarketingPreferencesTableHeaderCell'
import { MarketingPreferenceTableHeaderInfoBubble } from './Table/MarketingPreferenceTableHeaderInfoBubble'
import { MarketingPreferenceTableRow } from './Table/MarketingPreferenceTableRow'

const PAGE_SIZE = 25

export function MarketingPreferencesModal({
  venueId,
  venueGroupClientId,
  shown,
  onModalHidden,
}: {
  venueId: string
  venueGroupClientId: string
  shown: boolean
  onModalHidden: () => void
}) {
  const { formatMessage } = useLocales()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [cursors, setCursors] = useState<[string | null, string | null]>([null, null])

  const [getClientMarketingPreferences, { isLoading, data: marketingPreferences, isError: isLoadingError }, { lastArg: lastQueryArgs }] =
    useLazyGetClientMarketingPreferencesQuery()
  const [
    getClientCurrentVenuePreferences,
    { isLoading: isLoadingCurrentVenue, data: currentVenuePreferences, isError: isCurrentVenueError },
  ] = useLazyGetClientMarketingPreferencesForVenueQuery()
  const [updateClientMarketingPreferencesQuery, { isLoading: isSubmitting }] = useUpdateClientMarketingPreferencesMutation()
  const scrollBodyRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (shown) {
      getClientMarketingPreferences({
        venue: venueId,
        clientId: venueGroupClientId,
        limit: PAGE_SIZE,
      }).then(data => setCursors([null, data.data?.cursor || null]))
      getClientCurrentVenuePreferences({ venueId, clientId: venueGroupClientId })
    }
  }, [getClientMarketingPreferences, getClientCurrentVenuePreferences, venueGroupClientId, venueId, shown])

  const defaultValues = useMemo(() => {
    const vals = {
      optOutAllSms: false,
      optOutAllEmail: false,
      optInAllEmail: false,
      emailVenues: {} as { [k: string]: boolean },
      smsVenues: {} as { [k: string]: boolean },
    }
    if (marketingPreferences) {
      for (const venue of marketingPreferences.venues) {
        if (venue.emailOptInStatus !== ClientMarketingPreferenceOptInStatus.INITIAL) {
          vals.emailVenues[venue.venueId] = venue.emailOptInStatus === ClientMarketingPreferenceOptInStatus.SUBSCRIBED
        }
        vals.smsVenues[venue.venueId] = venue.smsOptInStatus === ClientMarketingPreferenceOptInStatus.SUBSCRIBED
      }
    }
    return vals
  }, [marketingPreferences])

  const isPaginated = (marketingPreferences?.totalVenueCount || 0) > PAGE_SIZE

  const form = useForm(useMarketingPreferencesFormSchema(), {
    defaultValues,
  })

  const {
    field,
    formState: { isDirty: formIsDirty, errors: formErrors },
  } = form

  const onHandleClose = useCallback(() => {
    form.reset()
    onModalHidden()
  }, [form, onModalHidden])

  const onHandleNav = async (cursorIndex: 0 | 1) => {
    const data = await getClientMarketingPreferences({
      venue: venueId,
      clientId: venueGroupClientId,
      limit: PAGE_SIZE,
      cursor: cursors[cursorIndex] || undefined,
      isCursorAfter: !cursorIndex,
    })
    if (data.data) {
      const { cursor } = data.data
      setCursors(cursors => (!cursorIndex ? [cursor || null, cursors[0]] : [cursors[1], cursor || null]))
      setCurrentPage(pageNum => pageNum + (!cursorIndex ? -1 : 1))
      if (scrollBodyRef.current) {
        scrollBodyRef.current.scrollTo(0, 0)
      }
    } else {
      notify({
        content: formatMessage(mgrClientMarketingPreferencesMessages.marketingPrefsRetrieveError),
        type: 'error',
      })
    }
  }
  const onHandleAllAction = (
    fieldForTrue: 'optInAllEmail' | 'optOutAllEmail' | 'optOutAllSms',
    fieldForFalse?: 'optInAllEmail' | 'optOutAllEmail'
  ) => {
    if (fieldForFalse) {
      form.setValue(fieldForFalse, false, { shouldDirty: false, shouldValidate: false, shouldTouch: false })
    }
    form.setValue(fieldForTrue, true, { shouldDirty: true, shouldValidate: true, shouldTouch: true })
  }
  const onHandleSubmit = useCallback(
    async (formData: MarketingPreferencesForm) => {
      try {
        await updateClientMarketingPreferencesQuery({
          venueId,
          clientId: venueGroupClientId,
          marketingPreferencesUpdate: formData,
        }).unwrap()
        notify({
          content: formatMessage(mgrClientMarketingPreferencesMessages.changesSaved),
          type: 'success',
        })
        if (!isPaginated) {
          onHandleClose()
        } else {
          getClientMarketingPreferences(lastQueryArgs).then(() => {
            form.reset()
          })
        }
      } catch {
        notify({
          content: formatMessage(mgrClientMarketingPreferencesMessages.errorUpdateFailed),
          type: 'error',
        })
      }
    },
    [
      form,
      formatMessage,
      getClientMarketingPreferences,
      isPaginated,
      lastQueryArgs,
      onHandleClose,
      updateClientMarketingPreferencesQuery,
      venueGroupClientId,
      venueId,
    ]
  )

  const lastIndex = currentPage * PAGE_SIZE + (marketingPreferences?.venues.length || 0)

  const isFetching = isLoading || (currentPage === 0 && isLoadingCurrentVenue)
  const hasFetchError = isLoadingError || (currentPage === 0 && isCurrentVenueError)

  const isContactingServer = isSubmitting || isFetching

  return (
    <Modal ariaLabel="Modal" width="600px" minWidth="600px">
      <ModalHeader onClose={onHandleClose}>
        <VStack spacing="s">
          <ModalTitle title={formatMessage(mgrClientMarketingPreferencesMessages.modalTitle)} />
          <Text>{formatMessage(mgrClientMarketingPreferencesMessages.modalSubheading)}</Text>
        </VStack>
      </ModalHeader>
      {isFetching && (
        <ModalBody minHeight="3rem" pb="m">
          <Loader />
        </ModalBody>
      )}
      {hasFetchError && (
        <ModalBody minHeight="3rem" pb="m">
          <Text>{formatMessage(mgrClientMarketingPreferencesMessages.marketingPrefsRetrieveError)}</Text>
        </ModalBody>
      )}
      {!isFetching && marketingPreferences && !hasFetchError && (
        <Form {...form} onSubmit={onHandleSubmit} onInvalid={() => {}}>
          <Box width="100%" pl="lm" pr="lm">
            <Table>
              <TableHeader>
                <MarketingPreferenceTableRow isHeader isLastRow={false} testId="header">
                  {[
                    <MarketingPreferenceTableHeaderCell
                      key="venues-header"
                      headerLabel={mgrClientMarketingPreferencesMessages.marketingPrefsTableHeadVenues}
                    />,
                    <MarketingPreferenceTableHeaderCell
                      key="email-header"
                      headerLabel={mgrClientMarketingPreferencesMessages.marketingPrefsTableHeadEmailMkt}
                      infoBubbleLines={[
                        mgrClientMarketingPreferencesMessages.marketingPrefsTableHeadEmailInfoSubscribe,
                        mgrClientMarketingPreferencesMessages.marketingPrefsTableHeadEmailInfoUnsubscribe,
                        mgrClientMarketingPreferencesMessages.marketingPrefsTableHeadEmailInfoResubscribe,
                      ]}
                    />,
                    <MarketingPreferenceTableHeaderCell
                      key="sms-header"
                      headerLabel={mgrClientMarketingPreferencesMessages.marketingPrefsTableHeadSMSMkt}
                      infoBubbleLines={[
                        mgrClientMarketingPreferencesMessages.marketingPrefsTableHeadSMSInfoSubscribe,
                        mgrClientMarketingPreferencesMessages.marketingPrefsTableHeadSMSInfoUnsubscribe,
                        mgrClientMarketingPreferencesMessages.marketingPrefsTableHeadSMSInfoResubscribe,
                      ]}
                    />,
                  ]}
                </MarketingPreferenceTableRow>
              </TableHeader>
            </Table>
          </Box>
          <Box
            overflowY="auto"
            maxHeight="calc(80vh - 210px)"
            pl="lm"
            pr="lm"
            pt="xs"
            pb="xs"
            borderBottomColor="borders"
            borderTopColor="borders"
            borderWidth="s"
            ref={scrollBodyRef}
          >
            <Table>
              <TableBody>
                {currentPage === 0 && (
                  <>
                    <MarketingPreferenceTableRow testId="all-actions" isFirstRow>
                      {[
                        <HStack columnGap="xs" key="all-0">
                          <Text fontWeight="bold">{formatMessage(mgrClientMarketingPreferencesMessages.marketingPrefsAllVenuesLabel)}</Text>
                          <MarketingPreferenceTableHeaderInfoBubble
                            lines={[mgrClientMarketingPreferencesMessages.marketingPrefsTableHeadVenuesInfo]}
                          />
                        </HStack>,
                        <VStack key="all-1" spacing="s">
                          <Button
                            variant="tertiary"
                            textWrap="pretty"
                            onClick={() => onHandleAllAction('optInAllEmail', 'optOutAllEmail')}
                            data-test="subscribe-all-button"
                          >
                            {formatMessage(mgrClientMarketingPreferencesMessages.subscribeAll)}
                          </Button>
                          <Button
                            variant="tertiary"
                            textWrap="pretty"
                            onClick={() => onHandleAllAction('optOutAllEmail', 'optInAllEmail')}
                            data-test="unsubscribe-all-button"
                          >
                            {formatMessage(mgrClientMarketingPreferencesMessages.unsubscribeAll)}
                          </Button>
                        </VStack>,
                        <VStack key="all-2" spacing="s">
                          <Button variant="tertiary" textWrap="pretty" disabled data-test="subscribe-all">
                            {formatMessage(mgrClientMarketingPreferencesMessages.subscribeAll)}
                          </Button>
                          <Button
                            variant="tertiary"
                            textWrap="pretty"
                            onClick={() => onHandleAllAction('optOutAllSms')}
                            data-test="unsubscribe-all"
                          >
                            {formatMessage(mgrClientMarketingPreferencesMessages.unsubscribeAll)}
                          </Button>
                        </VStack>,
                      ]}
                    </MarketingPreferenceTableRow>
                    {currentVenuePreferences && (
                      <VenuePreferenceRow
                        {...currentVenuePreferences}
                        emphasizeRow
                        isLastRow={marketingPreferences.venues.length === 1}
                        key="current-venue"
                        field={field}
                      />
                    )}
                  </>
                )}
                {(marketingPreferences.venues as ClientVenueMarketingPreference[]).map(
                  (venue: ClientVenueMarketingPreference, i: number) => {
                    if (currentPage === 0 && currentVenuePreferences && venue.venueId === currentVenuePreferences.venueId) {
                      return <></>
                    }
                    return (
                      <VenuePreferenceRow
                        {...venue}
                        key={venue.venueId}
                        emphasizeRow={false}
                        isLastRow={i === marketingPreferences.venues.length - 1}
                        isFirstRow={currentPage !== 0 && i === 0}
                        field={field}
                      />
                    )
                  }
                )}
              </TableBody>
            </Table>
          </Box>
          {formErrors && !!Object.values(formErrors).length && (
            <HStack justifyContent="end" mt="m" mr="l">
              <StatusLabel variant="error" data-test="mkt-pref-form-error-status">
                {Object.entries(formErrors)
                  .map(([f, e]) => `${f} - ${e.message}`)
                  .join('; ')}
              </StatusLabel>
            </HStack>
          )}

          <ModalFooter>
            <HStack alignItems="center" width="100%" justifyContent="space-between">
              <Box width="9rem">
                <SubmitButton
                  data-test="sr-marketing-prefs-save"
                  disabled={!formIsDirty || isContactingServer}
                  isSubmitting={isContactingServer}
                  size="m"
                  fullWidth
                >
                  {formatMessage(mgrClientMarketingPreferencesMessages.saveChanges)}
                </SubmitButton>
              </Box>
              {isPaginated && (
                <HStack columnGap="l" justifyContent="end" alignItems="center">
                  <Text>
                    {formatMessage(mgrClientMarketingPreferencesMessages.marketingPrefsPageIndicator, {
                      start: currentPage * PAGE_SIZE + 1,
                      end: lastIndex,
                      total: marketingPreferences.totalVenueCount,
                    })}
                  </Text>
                  <HStack spacing="xs" alignItems="center">
                    <Tooltip
                      disabled={isContactingServer || !formIsDirty || currentPage === 0}
                      content={
                        <VStack>
                          <Text color="lightFont">{formatMessage(mgrClientMarketingPreferencesMessages.dirtyNavWarningPrevious)}</Text>
                        </VStack>
                      }
                      displayAction="hover"
                      alignment="topRight"
                    >
                      <IconButton
                        borderType="none-round"
                        onClick={() => onHandleNav(0)}
                        disabled={isContactingServer || formIsDirty || currentPage === 0}
                        icon="VMSWeb-chevron-left"
                        data-test="mkt-pref-nav-prev"
                      />
                    </Tooltip>
                    <Tooltip
                      disabled={isContactingServer || !formIsDirty || lastIndex >= marketingPreferences.totalVenueCount}
                      content={
                        <VStack>
                          <Text color="lightFont">{formatMessage(mgrClientMarketingPreferencesMessages.dirtyNavWarningNext)}</Text>
                        </VStack>
                      }
                      displayAction="hover"
                      alignment="topRight"
                    >
                      <IconButton
                        borderType="none-round"
                        onClick={() => onHandleNav(1)}
                        disabled={isContactingServer || formIsDirty || lastIndex >= marketingPreferences.totalVenueCount}
                        icon="VMSWeb-chevron-right"
                        data-test="mkt-pref-nav-next"
                      />
                    </Tooltip>
                  </HStack>
                </HStack>
              )}
            </HStack>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  )
}
