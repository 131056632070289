import { type InternationalizationText, useLocales } from '@sevenrooms/core/locales'
import { InfoButton } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

interface MarketingPreferencesTableHeaderInfoBubbleProps {
  lines: InternationalizationText[]
}
export function MarketingPreferenceTableHeaderInfoBubble({ lines }: MarketingPreferencesTableHeaderInfoBubbleProps) {
  const { formatMessage } = useLocales()
  return (
    <InfoButton
      data-test="marketing-preferences-table-header-info-bubble"
      tooltip={{
        alignment: 'top',
        shouldCloseOnClick: true,
        captureCloseClick: true,
        content: (
          <VStack rowGap="m">
            {lines.map((t, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text textDisplay="block" color="lightFont" key={`${i}`}>
                {formatMessage(t, {
                  bold: (chunks: string[]) => (
                    <Text color="lightFont" fontWeight="bold">
                      {chunks}
                    </Text>
                  ),
                })}
              </Text>
            ))}
          </VStack>
        ),
      }}
    />
  )
}
