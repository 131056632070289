import { useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Window } from '@sevenrooms/core/ui-kit/layout'
import { MarketingPreferencesModal, mgrClientMarketingPreferencesMessages } from '@sevenrooms/mgr-clients'

export function MarketingPreferencesModalWrapper({ venueId, venueGroupClientId }: { venueId: string; venueGroupClientId: string }) {
  const [isShown, setIsShown] = useState<boolean>(false)
  const { formatMessage } = useLocales()

  return (
    <>
      <Button
        noPadding
        variant="tertiary"
        data-test="opt-in-modal-trigger"
        onClick={() => {
          setIsShown(true)
        }}
      >
        {formatMessage(mgrClientMarketingPreferencesMessages.triggerButtonCTA)}
      </Button>
      <Window active={isShown} setIsActive={setIsShown}>
        <MarketingPreferencesModal
          venueId={venueId}
          venueGroupClientId={venueGroupClientId}
          onModalHidden={() => setIsShown(false)}
          shown={isShown}
        />
      </Window>
    </>
  )
}
