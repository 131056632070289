import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Root } from '@sevenrooms/core/ui-kit/layout'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { AppRoot } from '@sevenrooms/mgr-core'
import { store } from '../pages/src/reduxStore'
import { MarketingPreferencesModalWrapper } from './MarketingPreferencesModalWrapper'

function renderClientMarketingPreferencesModal(id: string, venueId: string, venueGroupClientId: string) {
  return render(
    <AppRoot>
      <Provider store={store}>
        <Root theme="vx">
          <MarketingPreferencesModalWrapper venueId={venueId} venueGroupClientId={venueGroupClientId} />
        </Root>
      </Provider>
    </AppRoot>,
    document.getElementById(id)
  )
}

svrExport('ClientMarketingPreferencesModal', 'render', renderClientMarketingPreferencesModal)
