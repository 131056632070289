import { defineMessages } from '@sevenrooms/core/locales'

export const mgrClientMarketingPreferencesMessages = defineMessages({
  modalTitle: {
    id: 'clientMarketingPreferencesModal.title',
    defaultMessage: 'Manage Marketing Subscriptions',
  },
  saveChanges: {
    id: 'clientMarketingPreferencesModal.saveChanges',
    defaultMessage: 'Save Changes',
  },
  changesSaved: {
    id: 'clientMarketingPreferencesModal.changesSaved',
    defaultMessage: 'Changes saved',
  },
  optInStatusUnsubscribed: {
    id: 'clientMarketingPreferencesModal.optInStatusUnsubscribed',
    defaultMessage: 'Unsubscribed',
  },
  optInStatusNotSubscribed: {
    id: 'clientMarketingPreferencesModal.optInStatusNotSubscribed',
    defaultMessage: 'Not Subscribed',
  },
  optInStatusChannelUnavailable: {
    id: 'clientMarketingPreferencesModal.optInStatusChannelUnavailable',
    defaultMessage: '—',
  },
  modalSubheading: {
    id: 'clientMarketingPreferencesModal.modalSubheading',
    defaultMessage:
      'Update preferences based on the client’s request. Please note: Once a client is unsubscribed, you cannot resubscribe them here.',
  },
  marketingPrefsAllVenuesLabel: {
    id: 'clientMarketingPreferencesModal.marketingPrefsAllVenuesLabel',
    defaultMessage: 'All Venues',
  },
  marketingPrefsTableHeadVenues: {
    id: 'clientMarketingPreferencesModal.marketingPrefsTableHeadVenues',
    defaultMessage: 'Venues',
  },
  marketingPrefsTableHeadEmailMkt: {
    id: 'clientMarketingPreferencesModal.marketingPrefsTableHeadEmailMkt',
    defaultMessage: 'Email',
  },
  marketingPrefsTableHeadSMSMkt: {
    id: 'clientMarketingPreferencesModal.marketingPrefsTableHeadSMSMkt',
    defaultMessage: 'Text',
  },
  marketingPrefsTableHeadVenuesInfo: {
    id: 'clientMarketingPreferencesModal.marketingPrefsTableHeadVenuesInfo',
    defaultMessage: '<bold>Note:</bold> Subscribing to Venue Group will subscribe a client to all current and new locations.',
  },
  marketingPrefsTableHeadSMSInfoSubscribe: {
    id: 'clientMarketingPreferencesModal.marketingPrefsTableHeadSMSInfoSubscribe',
    defaultMessage: '<bold>To subscribe:</bold> Client must text your Text Marketing phone number or sign up on your website.',
  },
  marketingPrefsTableHeadSMSInfoUnsubscribe: {
    id: 'clientMarketingPreferencesModal.marketingPrefsTableHeadSMSInfoUnsubscribe',
    defaultMessage: '<bold>To unsubscribe:</bold> Uncheck the checkbox, or the client can text “STOP”.',
  },
  marketingPrefsTableHeadSMSInfoResubscribe: {
    id: 'clientMarketingPreferencesModal.marketingPrefsTableHeadSMSInfoResubscribe',
    defaultMessage: '<bold>To resubscribe:</bold> Client must text “START” to your Text Marketing phone number.',
  },
  marketingPrefsTableHeadEmailInfoSubscribe: {
    id: 'clientMarketingPreferencesModal.marketingPrefsTableHeadEmailInfoSubscribe',
    defaultMessage: '<bold>To subscribe:</bold> Check the checkbox. Please be sure they’ve given consent.',
  },
  marketingPrefsTableHeadEmailInfoUnsubscribe: {
    id: 'clientMarketingPreferencesModal.marketingPrefsTableHeadEmailInfoUnsubscribe',
    defaultMessage: '<bold>To unsubscribe:</bold> Uncheck the checkbox.',
  },
  marketingPrefsTableHeadEmailInfoResubscribe: {
    id: 'clientMarketingPreferencesModal.marketingPrefsTableHeadEmailInfoResubscribe',
    defaultMessage: '<bold>To resubscribe:</bold> Client can sign up on your website or via a link in reservation-related emails.',
  },
  marketingPrefsRetrieveError: {
    id: 'clientMarketingPreferencesModal.marketingPrefsRetrieveError',
    defaultMessage: "Could not get the client's marketing preferences; please try again later.",
  },
  marketingPrefsPageIndicator: {
    id: 'clientMarketingPreferencesModal.marketingPrefsPageIndicator',
    defaultMessage: 'Displaying {start} - {end} of {total} venues',
  },
  unsubscribeAll: {
    id: 'clientMarketingPreferencesModal.unsubscribeAll',
    defaultMessage: 'Unsubscribe from Venue Group',
  },
  subscribeAll: {
    id: 'clientMarketingPreferencesModal.subscribeAll',
    defaultMessage: 'Subscribe to Venue Group',
  },
  errorOptAllBothWays: {
    id: 'clientMarketingPreferences.errorOptAllBothWays',
    defaultMessage: 'You cannot both opt-in and opt-out all venues.',
  },
  errorUpdateFailed: {
    id: 'clientMarketingPreferences.errorUpdateFailed',
    defaultMessage: 'Could not save your changes. Please try again or contact support.',
  },
  triggerButtonCTA: {
    id: 'clientMarketingPreferences.triggerButtonCTA',
    defaultMessage: 'Manage Subscriptions',
  },
  dirtyNavWarningPrevious: {
    id: 'clientMarketingPreferencesModal.dirtyNavWarningPrevious',
    defaultMessage: 'To go to the previous page, first save changes.',
  },
  dirtyNavWarningNext: {
    id: 'clientMarketingPreferencesModal.dirtyNavWarningNext',
    defaultMessage: 'To go to the next page, first save changes.',
  },
})
