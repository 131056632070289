import { TableCell, TableRow } from '@sevenrooms/core/ui-kit/layout'
import type { ReactChild } from 'react'

const FIXED_WIDTH_COLUMN_WIDTH = '125px'

interface MarketingPreferenceTableRowProps {
  isLastRow?: boolean
  isHeader?: boolean
  isFirstRow?: boolean
  testId?: string
  children: [ReactChild, ReactChild, ReactChild]
}
export function MarketingPreferenceTableRow({ isLastRow, isHeader, isFirstRow, testId, children }: MarketingPreferenceTableRowProps) {
  return (
    <TableRow data-test={`mgr-mkt-prefs-${testId}`}>
      <TableCell
        removeLeftPadding
        noBorder
        isInLastRow={isLastRow}
        noBoxShadow
        isHeader={isHeader}
        noTopBorder={isHeader || isFirstRow}
        noBorderBottom={isHeader || isLastRow}
      >
        {children[0]}
      </TableCell>
      <TableCell
        noBorder
        isInLastRow={isLastRow}
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        textDisplay="flex"
        isHeader={isHeader}
        noTopBorder={isHeader || isFirstRow}
        noBorderBottom={isHeader || isLastRow}
        noBoxShadow
        width={FIXED_WIDTH_COLUMN_WIDTH}
        maxWidth={FIXED_WIDTH_COLUMN_WIDTH}
      >
        {children[1]}
      </TableCell>
      <TableCell
        noBorder
        removeRightPadding
        isInLastRow={isLastRow}
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        textDisplay="flex"
        isHeader={isHeader}
        noTopBorder={isHeader || isFirstRow}
        noBorderBottom={isHeader || isLastRow}
        noBoxShadow
        width={FIXED_WIDTH_COLUMN_WIDTH}
        maxWidth={FIXED_WIDTH_COLUMN_WIDTH}
      >
        {children[2]}
      </TableCell>
    </TableRow>
  )
}
